<template>
  <page-template>
    <main-block>
      <block-content>
        <row class="g-gs flex-column-reverse flex-lg-row">
          <column md="12" lg="9" xl="10">
            <card>
              <invoice-head>

              </invoice-head>
              <div class="my-2 font-weight-bold nk-text-muted">{{ $t('Invoice To') }}:</div>
              <row class="justify-content-between" v-if="invoiceData.user.first_name.length > 0">
                <column default="auto">
                  <template v-if="invoiceData.invoice_address.address">
                    <b class="d-block nk-text-muted">{{ invoiceData.invoice_address.company_name }}</b>
                    <div class="nk-text-muted">{{ invoiceData.invoice_address.address }}</div>
                  </template>
                  <template v-else>
                    <b class="d-block nk-text-muted">{{ invoiceData.user.company }}</b>
                    <div class="nk-text-muted">
                      {{ invoiceData.user.first_name +' '+invoiceData.user.last_name }}<br/>
                      {{ invoiceData.user.house_no }}, {{invoiceData.user.street}}<br/>
                      {{ invoiceData.user.post_code }}
                    </div>
                  </template>
                </column>
                <column default="auto" lg="4" xl="3" class="mt-4 mt-md-0">
                  <div style="color: #58388d;font-size: 1.1rem;font-weight: 600;">{{$t('Invoice')}} #{{ invoiceData.invoice_number }}</div>
                  <div>
                    <span class="nk-text-muted">{{ $t('Date Issued') }}: </span>
                    <span class="nk-text-muted font-weight-bold">{{ invoiceData.issue_date }}</span>
                  </div>
                </column>
              </row>
              <row class="justify-content-between" v-else>
                <column default="12" lg="4" xl="3">
                  <ion-skeleton-text animated style="width: 60%" class="mb-1"></ion-skeleton-text>
                  <ion-skeleton-text animated></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                </column>
                <column default="auto" lg="4" xl="3">
                  <ion-skeleton-text animated style="height: 30px;"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                </column>
              </row>
              <div class="my-2">
                <div v-if="invoiceData.period">
                  <span class="nk-text-muted">{{$t('Period')}}: </span>
                  <span class="nk-text-muted font-weight-bold">{{ invoiceData.period }}</span>
                </div>
                <ion-skeleton-text animated v-else style="width: 200px" class="mt-2"></ion-skeleton-text>
              </div>
              <table class="table ml-n3 ml-md-0" id="invoice_items_table" v-if="invoiceData.id">
                <thead class="thead-light">
                <tr class="active text-uppercase">
                  <th class="nk-text-muted">{{ $t('Definition') }}</th>
                  <th class="nk-text-muted">{{ $t('Amount') }}</th>
                  <th class="nk-text-muted">{{ $t('Quantity')}}</th>
                  <th class="nk-text-muted" style="width: 120px">{{ $t('Total') }}</th>
                </tr>
                </thead>
                <tr v-for="(item,index) in invoiceData.items" :key="index">
                  <td><span class="d-block d-md-inline">Lead #</span>{{ item.lead_number }}</td>
                  <td>&euro;{{ item.amount }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>&euro;{{ item.total_amount }}</td>
                </tr>
                <tr v-if="!invoiceData.items.length">
                  <td colspan="4" class="text-muted">{{ $t('pages.invoices.no_items') }}</td>
                </tr>
              </table>
              <div v-else class="mt-4">
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                <ion-skeleton-text animated></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              </div>
              <row class="justify-content-end">
                <column default="8" md="4" xl="3" class="border-bottom my-5 pb-2">
                  <row>
                    <column default="6" class="nk-text-muted">{{$t('Total')}}: </column>
                    <column default="6" class="nk-text-muted font-weight-bold">
                      <span v-if="invoiceData.total_amount">&euro;{{ invoiceData.total_amount }}</span>
                      <ion-skeleton-text animated v-else style="height: 20px"></ion-skeleton-text>
                    </column>
                  </row>
                </column>
              </row>
              <invoice-footer>

              </invoice-footer>
            </card>
          </column>
          <column md="12" lg="3" xl="2" id="invoice_action_btns">
            <card>

              <nk-button type="dark" full-width v-on:click="prepareInvoiceDownload(invoiceData.id)" :disabled="!invoiceData.id">{{$t('Download')}}</nk-button>
              <nk-button type="dark" outline full-width class="d-none d-md-block mb-lg-4" onclick="window.print()">{{$t('Print')}}</nk-button>

              <router-link :to="{name: 'adminInvoices'}" class="btn btn-outline-dark mt-4 mt-lg-5 d-inline-block d-lg-block text-center">
                <nio-icon icon="ni-arrow-left" class="float-left"></nio-icon> {{ $t('All invoices')}}
              </router-link>
            </card>
          </column>
        </row>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>

import {IonSkeletonText, onIonViewWillEnter, toastController} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';

import {Card} from "@core/components/cards";
import InvoiceHead from "@/views/invoice/components/InvoiceHead";
import InvoiceFooter from "@/views/invoice/components/InvoiceFooter";

import { useRoute } from "vue-router"
import invoiceFunctions from "../../invoices/functions";
import useCommonFunc from "@core/comp-functions/common"
import {useI18n} from "vue-i18n";
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    InvoiceFooter,
    InvoiceHead,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    IonSkeletonText,
  },
  setup() {

    const {t} = useI18n()
    const route = useRoute()
    let invoiceData = reactive({
      created_at: '',
      id: '',
      invoice_address:{
        address: '',
        company_name: '',
      },
      invoice_number: '',
      issue_date: '',
      items: [],
      period: '',
      status_text: '',
      total_amount: '',
      user: {
        company: '',
        first_name: '',
        house_no: '',
        last_name: '',
        post_code: '',
        street: '',
      }
    })

    const { getInvoiceData, prepareInvoiceDownload } = invoiceFunctions()
    const { updateObject } = useCommonFunc()

    onIonViewWillEnter(() => {
      getInvoiceData(route.params.id)
          .then((d) => updateObject(invoiceData, d))
          .catch(err => {
            toastController.create({header: t('Error')+'!', message: err, color: 'danger', duration: 3500}).then((t)=> t.present())
          })
    })

    return {
      invoiceData,
      prepareInvoiceDownload,
    }
  }
});
</script>
<style scoped>
#invoice_items_table thead th{
  font-size: .7rem;
}
@media print {
  .nk-sidebar, .nk-header, #invoice_action_btns{
    display: none;
  }
}
</style>
